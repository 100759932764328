import Splide from '@splidejs/splide';
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
import intlTelInput from "intl-tel-input";
import 'intl-tel-input/build/js/intlTelInputWithUtils.js';
import 'intl-tel-input/build/js/intlTelInput.js';

// Your app code
// console.log(`index.js`);

const splide = new Splide(".splide", {
  type: 'loop',
  drag: 'false',
  focus: 'center',
  perPage: 3,
  gap: 20,
  autoScroll: {
    speed: 1,
  },
  breakpoints: {
    576: {
      perPage: 1,
    }
  },
  arrows: false,
  pagination: false,
  autoWidth: false,
  autoHeight: true
})
splide.mount({AutoScroll});

var prevElement = document.getElementById("review-left-arrow");
prevElement.addEventListener('click', () => {
  splide.go("-1");
})

var nextElement = document.getElementById("review-right-arrow");
nextElement.addEventListener('click', () => {
  splide.go("+1");
})


export class BeeBloomGallery {
  constructor(backgroundId, imageClass, timeout) {
    this.backgroundId = backgroundId;
    this.imageClass = imageClass;
    this.timeout = timeout || 1000;
    // console.log(backgroundId)
    this.backgroundDiv = document.getElementById(backgroundId);
    // console.log(this.backgroundDiv)
    this.imageDivs = document.getElementsByClassName(imageClass);
    this.currentImage = 0;
    this.autoPlayInterval = null;
    this.changeImage = this.changeImage.bind(this);
    this.changeImagesAutoPlay = this.changeImagesAutoPlay.bind(this);
    this.stopAutoPlay = this.stopAutoPlay.bind(this);

  }

  changeImage(index) {
    // console.log(index);
    var element = this.imageDivs[index];
    // console.log("src", element.src);
    this.backgroundDiv.src = `${element.src}`;
  }

  changeImagesAutoPlay() {
    this.autoPlayInterval = setInterval(() => {
      var nextImage = (this.currentImage + 1) % this.imageDivs?.length;
      this.changeImage(nextImage);
      this.currentImage = nextImage;
    }, this.timeout);
  }

  stopAutoPlay() {
    // console.log("stop");
    this.changeImage(1);
    clearInterval(this.autoPlayInterval);
  }
}

var beeBoomGallery = new BeeBloomGallery("beebloom-preview-image", "beebloom-gallery-image", 500);

const eventGallery = new BeeBloomGallery("event-replay-preview-image", "event-replay-images", 500);
// eventGallery.changeImagesAutoPlay();

const datingGallery = new BeeBloomGallery("dating-preview-image", "dating-gallery-images", 500);
// datingGallery.changeImagesAutoPlay();

const fuelDeskGallery = new BeeBloomGallery("fuel-desk-preview-image", "fuel-desk-gallery-images", 500);
// fuelDeskGallery.changeImagesAutoPlay();


document.getElementById("beebloom-preview-image").addEventListener("mouseover", beeBoomGallery.changeImagesAutoPlay);
document.getElementById("beebloom-preview-image").addEventListener("mouseout", beeBoomGallery.stopAutoPlay);

document.getElementById("event-replay-preview-image").addEventListener("mouseover", eventGallery.changeImagesAutoPlay);
document.getElementById("event-replay-preview-image").addEventListener("mouseout", eventGallery.stopAutoPlay);

document.getElementById("dating-preview-image").addEventListener("mouseover", datingGallery.changeImagesAutoPlay);
document.getElementById("dating-preview-image").addEventListener("mouseout", datingGallery.stopAutoPlay);

document.getElementById("fuel-desk-preview-image").addEventListener("mouseover", fuelDeskGallery.changeImagesAutoPlay);
document.getElementById("fuel-desk-preview-image").addEventListener("mouseout", fuelDeskGallery.stopAutoPlay);


//web app and design

var webAppBeeBoomGallery = new BeeBloomGallery("web-app-design-beebloom", "web-app-beebloom-image", 500);
// webAppBeeBoomGallery.changeImagesAutoPlay();


var webAppEventGallery = new BeeBloomGallery("web-app-event-replay", "web-app-event-replay-image", 500);
// webAppEventGallery.changeImagesAutoPlay();


const webAppDatingGallery = new BeeBloomGallery("web-app-dating", "web-app-dating-gallery", 500);
// webAppDatingGallery.changeImagesAutoPlay();


const webAppFuelDeskGallery = new BeeBloomGallery("web-app-fuel-desk", "web-app-fuel-desk-image", 500);
// webAppFuelDeskGallery.changeImagesAutoPlay();

// web app and design

document.getElementById("web-app-design-beebloom").addEventListener("mouseover", webAppBeeBoomGallery.changeImagesAutoPlay);
document.getElementById("web-app-design-beebloom").addEventListener("mouseout", webAppBeeBoomGallery.stopAutoPlay);

document.getElementById("web-app-event-replay").addEventListener("mouseover", webAppEventGallery.changeImagesAutoPlay);
document.getElementById("web-app-event-replay").addEventListener("mouseout", webAppEventGallery.stopAutoPlay);

document.getElementById("web-app-dating").addEventListener("mouseover", webAppDatingGallery.changeImagesAutoPlay);
document.getElementById("web-app-dating").addEventListener("mouseout", webAppDatingGallery.stopAutoPlay);

document.getElementById("web-app-fuel-desk").addEventListener("mouseover", webAppFuelDeskGallery.changeImagesAutoPlay);
document.getElementById("web-app-fuel-desk").addEventListener("mouseout", webAppFuelDeskGallery.stopAutoPlay);


//content writing

var contentBeeBoomGallery = new BeeBloomGallery("content-beebloom-preview-image", "content-beebloom-image", 500);
// contentBeeBoomGallery.changeImagesAutoPlay();

var contentEventGallery = new BeeBloomGallery("content-events-preview-image", "content-events-image", 500);
// contentEventGallery.changeImagesAutoPlay();

const contentDatingGallery = new BeeBloomGallery("content-dating-preview-image", "content-dating-image", 500);
// contentDatingGallery.changeImagesAutoPlay();

const contentFuelDeskGallery = new BeeBloomGallery("content-fuel-desk-preview-image", "content-fuel-desk-image", 500);
// contentFuelDeskGallery.changeImagesAutoPlay();

// web app and design

document.getElementById("content-beebloom-preview-image").addEventListener("mouseover", contentBeeBoomGallery.changeImagesAutoPlay);
document.getElementById("content-beebloom-preview-image").addEventListener("mouseout", contentBeeBoomGallery.stopAutoPlay);

document.getElementById("content-events-preview-image").addEventListener("mouseover", contentEventGallery.changeImagesAutoPlay);
document.getElementById("content-events-preview-image").addEventListener("mouseout", contentEventGallery.stopAutoPlay);

document.getElementById("content-dating-preview-image").addEventListener("mouseover", contentDatingGallery.changeImagesAutoPlay);
document.getElementById("content-dating-preview-image").addEventListener("mouseout", contentDatingGallery.stopAutoPlay);

document.getElementById("content-fuel-desk-preview-image").addEventListener("mouseover", contentFuelDeskGallery.changeImagesAutoPlay);
document.getElementById("content-fuel-desk-preview-image").addEventListener("mouseout", contentFuelDeskGallery.stopAutoPlay);


// graphic design

var graphicBeeBoomGallery = new BeeBloomGallery("graphic-beebloom-preview-image", "graphic-beebloom-gallery-image", 500);
// graphicBeeBoomGallery.changeImagesAutoPlay();

var graphicEventGallery = new BeeBloomGallery("graphic-event-replay-preview-image", "graphic-event-replay-images", 500);
// graphicEventGallery.changeImagesAutoPlay();

const graphicDatingGallery = new BeeBloomGallery("graphic-dating-preview-image", "graphic-dating-gallery-images", 500);
// graphicDatingGallery.changeImagesAutoPlay();

const graphicFuelDeskGallery = new BeeBloomGallery("graphic-fuel-desk-preview-image", "graphic-fuel-desk-gallery-images", 500);
// graphicFuelDeskGallery.changeImagesAutoPlay();

document.getElementById("graphic-beebloom-preview-image").addEventListener("mouseover", graphicBeeBoomGallery.changeImagesAutoPlay);
document.getElementById("graphic-beebloom-preview-image").addEventListener("mouseout", graphicBeeBoomGallery.stopAutoPlay);

document.getElementById("graphic-event-replay-preview-image").addEventListener("mouseover", graphicEventGallery.changeImagesAutoPlay);
document.getElementById("graphic-event-replay-preview-image").addEventListener("mouseout", graphicEventGallery.stopAutoPlay);

document.getElementById("graphic-dating-preview-image").addEventListener("mouseover", graphicDatingGallery.changeImagesAutoPlay);
document.getElementById("graphic-dating-preview-image").addEventListener("mouseout", graphicDatingGallery.stopAutoPlay);

document.getElementById("graphic-fuel-desk-preview-image").addEventListener("mouseover", graphicFuelDeskGallery.changeImagesAutoPlay);
document.getElementById("graphic-fuel-desk-preview-image").addEventListener("mouseout", graphicFuelDeskGallery.stopAutoPlay);

const phoneNumberInputEl = document.querySelector("#exampleFormControlPhoneNumberInput");
const iti = intlTelInput(phoneNumberInputEl, {
  utilsScript: "/js/utils.js",
  initialCountry: 'IN',
  separateDialCode: true,
  showFlags: true,
});

document.getElementById('exampleFormControlPhoneNumberInput').addEventListener('input', function (e) {
  // Remove non-digit characters
  this.value = this.value.replace(/\D/g, '');
});

window.addEventListener("load", function () {
  const form = document.getElementById('contact-form');
  const captchaInput = document.getElementById('captchaInput');
  const captchaError = document.getElementById('captchaError');
  const captchaQuestionElement = document.getElementById('captchaQuestion');
  let captchaAnswer = null;

  function generateCaptcha() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    captchaAnswer = captcha;
    captchaQuestionElement.textContent = `${captcha}`;
  }

  function validateCaptcha() {
    return captchaInput.value === captchaAnswer;
  }

  generateCaptcha();

  form.addEventListener("submit", function (e) {
    e.preventDefault();

    if (!validateCaptcha()) {
      captchaError.textContent = "Incorrect CAPTCHA, please try again.";
      return; // Stop form submission if CAPTCHA is incorrect
    }

    captchaError.textContent = ""; // Clear previous error messages

    document.getElementById('loading-spinner').style.display = 'inline-block';
    const phoneNumberWithCountryCode = iti.getNumber();
    const data = new FormData(form);
    data.set("PhoneNumber", phoneNumberWithCountryCode);
    data.append('CountryCode', iti.getSelectedCountryData().dialCode);
    data.append('CountryName', iti.getSelectedCountryData().name);
    data.append('origin', window.location.origin);
    const action = e.target.action;
    fetch(action, {
      method: 'POST',
      body: data,

    })
      .then((response) => {
        response.json().then(data => {
          if(data.result === 'error') {
            document.getElementById("display-msg").textContent = "There was an error while submitting the form. Please try again later.";
            document.getElementById("msg-container").classList.add("error-msg-display-container");
            document.getElementById('loading-spinner').style.display = 'none';
            form.reset();
            generateCaptcha();
          }
        })
      })
      .then(() => {
        document.getElementById("display-msg").textContent = "Your form was submitted successfully. Thank you, will get back to you soon!";
        document.getElementById("msg-container").classList.add("success-msg-display-container");
        document.getElementById('loading-spinner').style.display = 'none';
        form.reset();
        generateCaptcha();
        // alert("Success!");
      }).catch(() => {
      document.getElementById("display-msg").textContent = "There was an error while submitting the form. Please try again later.";
      document.getElementById("msg-container").classList.add("error-msg-display-container");
      document.getElementById('loading-spinner').style.display = 'none';
      form.reset();
      generateCaptcha();
    })
  });
});
